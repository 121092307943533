import { actionTypes } from '@/store/modules/modals'
import popUpTypes from '@/types/pop-up-types'
import getAllowedByRole from '@/helpers/getAllowedByRole'
const { calendarContext, calendarAddForUsa } = popUpTypes

const scheduleAppointmentHandle = (wig, store, getAllowed) => {
    const isWigCreatedByUsa =
        getAllowed(['usa_manager']) || wig?.owner?.role === 'USA_MANAGER'

    const payload = {
        action: 'for-wig',
        wigId: wig.id,
        meetingManagerId: null,
        type: isWigCreatedByUsa
            ? 'us_agent'
            : !wig['is_repair']
            ? 'pick_up_new'
            : 'pick_up_repairs',
    }
    store.dispatch(`modals/${actionTypes.openPopUp}`, {
        name: calendarContext,
        payload,
    })
}

const scheduleAppointmentValidator = (wig) => {
    if (!getAllowedByRole(['admin', 'manager'])) {
        return false
    }

    if (wig?.state?.toLowerCase() === 'archived') return false
    return !wig.complete_data && wig.state !== 'Rework'
}

const scheduleAppointmentForUsaHandle = (wig, store) => {
    const payload = {
        ['wig_id']: wig.id,
        ['client_id']: wig.client_id,
    }

    store.dispatch(`modals/${actionTypes.openPopUp}`, {
        name: calendarAddForUsa,
        payload,
    })
}

const scheduleAppointmentForUsaValidator = (wig) => {
    if (!getAllowedByRole(['usa_manager']) || wig.state !== 'Draft') {
        return false
    }

    return !wig.complete_data
}

const showScheduledAppointmentHandle = (wig, store, getAllowed) => {
    const isWigCreatedByUsa =
        getAllowed(['usa_manager']) || wig?.owner?.role === 'USA_MANAGER'

    const payload = {
        action: 'to-date',
        selectDate: wig['complete_data'],
        type: isWigCreatedByUsa
            ? 'us_agent'
            : !wig['is_repair']
            ? 'pick_up_new'
            : 'pick_up_repairs',
    }

    store.dispatch(`modals/${actionTypes.openPopUp}`, {
        name: calendarContext,
        payload,
    })
}

const showScheduledAppointmentValidator = (wig) => {
    if (!getAllowedByRole(['admin', 'manager', 'usa_manager'])) {
        return false
    }

    return !!wig.complete_data
}

// Specific rework rules
const goToMeetingHandle = (wig, store) => {
    const meetingManager = wig?.suggestedMeetingManager?.id || null

    const payload = {
        action: 'for-wig',
        wigId: wig.id,
        meetingManagerId: meetingManager,
        type: wig['is_repair'] === 1 ? 'pick_up_repairs' : 'pick_up_new',
    }

    store.dispatch(`modals/${actionTypes.openPopUp}`, {
        name: calendarContext,
        payload,
    })
}

const goToMeetingValidator = (wig) => {
    if (!getAllowedByRole(['admin', 'manager'])) {
        return false
    }

    return wig.state === 'Rework'
}

export const completeDateActions = [
    {
        titleKey: 'scheduleAppointment',
        handle: scheduleAppointmentHandle,
        validator: scheduleAppointmentValidator,
    },
    {
        titleKey: 'scheduleAppointmentForUSA',
        handle: scheduleAppointmentForUsaHandle,
        validator: scheduleAppointmentForUsaValidator,
    },
    {
        titleKey: 'showScheduledAppointment',
        handle: showScheduledAppointmentHandle,
        validator: showScheduledAppointmentValidator,
    },
    {
        titleKey: 'rescheduleForRework',
        handle: goToMeetingHandle,
        validator: goToMeetingValidator,
    },
]
