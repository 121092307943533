<template>
    <div class="calendar-main">
        <div class="calendar-main__head">
            <div
                v-if="type !== 'list'"
                class="calendar-main__left-action left-action"
            >
                <v-btn
                    class="left-action__btn"
                    :[cusSizes]="true"
                    dense
                    color="#967adc"
                    outlined
                    @click="onPrev"
                >
                    <v-icon
                        >{{ isRtl ? 'mdi-chevron-right' : 'mdi-chevron-left' }}
                    </v-icon>
                    {{ textContent['prev'] }}
                </v-btn>

                <v-btn
                    class="left-action__btn"
                    :[cusSizes]="true"
                    dense
                    color="#967adc"
                    outlined
                    @click="onNext"
                >
                    {{ textContent['next'] }}
                    <v-icon
                        >{{ isRtl ? 'mdi-chevron-left' : 'mdi-chevron-right' }}
                    </v-icon>
                </v-btn>

                <v-btn
                    class="left-action__today"
                    :[cusSizes]="true"
                    dense
                    color="#967adc"
                    outlined
                    @click="moveToCurrent"
                >
                    {{ textContent['today'] }}
                </v-btn>
                <div class="left-action__search-query">
                    <v-text-field
                        id="left-action__search-query"
                        v-model="searchQuery"
                        name="calendarSearchQuery"
                        :label="$t('pages.wigPage.associatePopup.search')"
                        height="20"
                        light
                        dense
                        hide-details
                        type="text"
                        @input="searchQueryUpdate"
                    >
                    </v-text-field>
                </div>
                <div class="left-action__loader">
                    <v-progress-circular
                        v-if="meetingsIsFetching"
                        indeterminate
                        :width="3"
                        :size="24"
                        color="primary"
                    ></v-progress-circular>
                </div>
            </div>
            <div v-if="type !== 'list'" class="calendar-main__title">
                <h3 class="calendar-main__type">
                    {{ text(kind) }}
                </h3>
                <p class="calendar-main__selected-date">
                    {{ dateForHead }}
                </p>
            </div>
            <div class="calendar-main__right-action">
                <template v-if="isMobile">
                    <v-select
                        v-model="type"
                        color="#967adc"
                        dense
                        :items="types"
                        outlined
                        label="Format"
                        @change="(value) => onSwitchType(value)"
                    ></v-select>
                </template>
                <template v-else>
                    <v-btn-toggle dark mandatory>
                        <v-btn
                            dark
                            small
                            dense
                            color="#967adc"
                            @click="() => onSwitchType('month')"
                        >
                            {{ textContent['month'] }}
                        </v-btn>
                        <v-btn
                            dark
                            small
                            dense
                            color="#967adc"
                            @click="() => onSwitchType('week')"
                        >
                            {{ textContent['week'] }}
                        </v-btn>
                        <v-btn
                            dark
                            small
                            dense
                            color="#967adc"
                            @click="() => onSwitchType('day')"
                        >
                            {{ textContent['day'] }}
                        </v-btn>
                        <v-btn
                            dark
                            small
                            dense
                            color="#967adc"
                            @click="() => onSwitchType('list')"
                        >
                            {{ 'List' }}
                        </v-btn>
                    </v-btn-toggle>
                </template>
            </div>
        </div>
        <template v-if="type === 'list'">
            <CalendarMeetingList
                @on-update="updateHandle"
                @on-delete="deleteHandle"
            />
        </template>
        <v-calendar
            v-else
            ref="calendar"
            :key="calendarKey"
            v-model="value"
            event-start="startAt"
            event-end="endAt"
            :event-margin-bottom="5"
            :event-name="'name'"
            first-time="09:00"
            :event-more="false"
            :interval-count="intervalCount"
            :weekdays="[0, 1, 2, 3, 4]"
            min-weeks="1"
            :weekday-format="weekdayFormat"
            :max-days="5"
            :short-intervals="false"
            :start="start"
            :interval-minutes="30"
            :event-overlap-mode="'column'"
            :event-overlap-threshold="60"
            :interval-width="50"
            interval-height="55"
            :events="filterByMeetingManager(meetings)"
            :type="type"
            :event-height="eventHeight"
            :interval-format="intervalFormat"
            @click:event="openEvent"
            @click:day="createMeeting"
            @click:date="selectDay"
            @click:time="createMeeting"
            @change="changeHandle"
        >
            <!--    eslint-disable-next-line -->
            <template #event="{ event, eventParsed }">
                <template v-if="event">
                    <CalendarEventName
                        :event="{
                            hour: eventParsed.start.hour,
                            minute: eventParsed.start.minute,
                            text: event,
                            typeOfCalendar: type,
                            isNewWig: event?.wig?.is_repair !== 1 || false,
                            isRepairWig: event?.wig?.is_repair === 1 || false,
                            manager: event.manager,
                            meetingManager: event.meeting_manager,
                        }"
                    />
                </template>
            </template>
        </v-calendar>
        <v-menu
            v-model="selectedOpen"
            max-width="200"
            min-width="200"
            :close-on-content-click="false"
            :activator="selectedElement"
        >
            <div class="meeting-more">
                <div class="meeting-more__head">
                    <v-btn icon x-small dense @click="closeEvent">
                        <v-icon small>mdi-close-circle</v-icon>
                    </v-btn>
                </div>

                <template v-if="selectedOpen">
                    <CalendarEventExpandedCard
                        :event-details="selectedEvent"
                        @update-handle="updateHandle"
                        @delete-handle="deleteHandle"
                    />
                </template>
            </div>
        </v-menu>
    </div>
</template>

<script>
import CalendarEventName from '@/components/calendar/calendar-event-name'

import { getRange } from '@/helpers/rangeFromDate'
import { actionTypes } from '@/store/modules/calendar'
import { mapActions, mapGetters } from 'vuex'
import { actionTypes as actionTypesModalNotifications } from '@/store/modules/modal-notifications'
import { moveToWorkingDay } from '@/components/calendar/calc'
import { parserForTime } from '@/helpers/parser-for-time'
import { actionTypes as actionTypesConfirmer } from '@/store/modules/confirmer'
import { getterTypes as calendarGetterTypes } from '@/store/modules/calendar'
import { actionTypes as actionTypesClients } from '@/store/modules/clients'
import { actionTypes as actionTypesOrders } from '@/store/modules/orders'
import { getInMilliseconds } from '@/helpers/getDateInFormat'
import { getterTypes as gettersTypesModals } from '@/store/modules/modals'
import languageMixin from '@/mixins/language.mixin'
import regularToSnakeCase from '@/helpers/regular-to-snake-case'
import parseFromInternToHePhoneFormat from '@/helpers/parse-from-intern-to-he-phone-format'
import popUpTypes from '@/types/pop-up-types'
import { format, parseISO } from 'date-fns'
import wigTransitions from '@/helpers/transitions/wig-transitions'
import getUniqueDatesByDate from '@/components/calendar/get-unique-dates-by-date.utils'
import CalendarEventExpandedCard from '@/components/calendar/calendar-event-expanded-card.vue'
import CalendarMeetingList from '@/components/calendar/calendar-meeting-list.vue'
import { meetingListActionTypes } from '@/store/modules/meeting-list/types'

const { calendarContext } = popUpTypes

export default {
    name: 'CalendarMain',
    components: {
        CalendarMeetingList,
        CalendarEventExpandedCard,
        CalendarEventName,
    },
    mixins: [languageMixin],
    props: {
        // eslint-disable-next-line vue/require-default-prop
        startData: null,
        meetings: {
            type: Array,
            default: () => [],
        },
        typeOfMeetings: {
            type: String,
            default: 'orders',
        },
        typeOfFormat: {
            type: String,
            default: 'month',
        },
        selectedManagers: {
            type: Array,
            default: () => [],
        },
    },
    emits: [
        'load-range',
        'create-meeting',
        'update-handle',
        'select-day',
        'change-type',
    ],
    data() {
        return {
            selectedMeetingId: null,
            selectedEvent: {},
            selectedElement: null,
            selectedOpen: false,
            value: undefined,
            calendarKey: 1,
            calendarUpdateTimeout: null,
            range: {
                start: {
                    year: null,
                    month: null,
                    day: null,
                },
                end: {
                    year: null,
                    month: null,
                    day: null,
                },
            },
            type: 'month',
            intervalCount: 12,
            searchQuery: '',
            searchQueryTimeout: null,
        }
    },
    computed: {
        ...mapGetters('calendar', {
            meetingsIsFetching: calendarGetterTypes.isFetching,
        }),
        ...mapGetters('modals', {
            checkIsActive: gettersTypesModals.isActive,
            payload: gettersTypesModals.payload,
        }),
        isActive: function () {
            return this.checkIsActive(calendarContext)
        },
        months: function () {
            const en = [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
            ]
            const he = [
                'יָנוּאָר',
                'פברואר',
                'מרץ',
                'אַפּרִיל',
                'מאי',
                'יוני',
                'יולי',
                'אוגוסט',
                'סֶפּטֶמבֶּר',
                'אוֹקְטוֹבֶּר',
                'נוֹבֶמבֶּר',
                'דֵצֶמבֶּר',
            ]
            return this.isRtl ? he : en
        },
        monthsShort: function () {
            const en = [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec',
            ]
            const he = [
                'יָנוּאָר',
                'פברואר',
                'מרץ',
                'אַפּרִיל',
                'מאי',
                'יוני',
                'יולי',
                'אוגוסט',
                'סֶפּטֶמבֶּר',
                'אוֹקְטוֹבֶּר',
                'נוֹבֶמבֶּר',
                'דֵצֶמבֶּר',
            ]
            return this.isRtl ? he : en
        },
        eventHeight: function () {
            return this.type === 'day' ? 43 : 20
        },
        cusSizes: function () {
            return this.isMobile ? 'normal' : 'x-small'
        },
        currentDate: function () {
            const current = moveToWorkingDay()
            return {
                year: current.getFullYear(),
                month: current.getMonth(),
                day: current.getDate(),
            }
        },
        kind: function () {
            switch (this.typeOfMeetings.toLowerCase()) {
                case 'pick_up_new':
                    return 'Pick Up New'
                case 'pick_up_repairs':
                    return 'Pick Up Repairs'
                case 'repairs':
                    return 'Repairs'
                case 'us_agent':
                    return 'US Agent'
                default:
                    return 'Orders'
            }
        },
        selectedTime: function () {
            if (!this.selectedEvent) return ''
            const date = new Date(getInMilliseconds(this.selectedEvent.startAt))

            const h = date.getHours()
            let m = date.getMinutes()
            m = m.toString().length === 1 ? '0' + m : m
            return `${h}:${m}`
        },
        start: function () {
            const { year, month, day } = this.currentDate
            return this.startData
                ? new Date(this.startData)
                : new Date(year, month, day)
        },
        dateForHead: function () {
            const start = this.range.start
            const end = this.range.end

            switch (this.type) {
                case 'week':
                    return `${this.monthsShort[start.month - 1]} ${
                        start.day
                    } - ${
                        start.month !== end.month
                            ? this.monthsShort[end.month - 1] + ' '
                            : ''
                    }${end.day}, ${start.year}`
                case 'day':
                    return `${this.months[start.month - 1]} ${start.day}, ${
                        start.year
                    }`
                default:
                    return `${this.months[start.month - 1]} ${start.year}`
            }
        },
        clientName() {
            return `${this.selectedEvent?.client?.name} ${this.selectedEvent?.client?.surname}`
        },
        currentDay() {
            return this.range?.start?.date || ''
        },
        types() {
            return [
                { text: this.textContent['month'], value: 'month' },
                { text: this.textContent['week'], value: 'week' },
                { text: this.textContent['day'], value: 'day' },
                { text: 'list', value: 'list' },
            ]
        },
    },
    watch: {
        typeOfMeetings: function () {
            this.onChange()
            this.pickUpIntervalChange()
        },
        currentDay: function (value) {
            this.$emit('select-day', value)
        },
        isActive: function (value) {
            if (value) this.type = this.typeOfFormat
        },
        startData: function () {
            this.value = null
            this.onChange()
        },
        meetings: function () {
            if (this.calendarUpdateTimeout) {
                clearTimeout(this.calendarUpdateTimeout)
                this.calendarUpdateTimeout = null
            }

            this.calendarUpdateTimeout = setTimeout(() => {
                this.calendarKey = this.calendarKey === 1 ? 2 : 1
                this.calendarUpdateTimeout = null
            }, 300)
        },
    },
    mounted() {
        this.onChange()
    },
    methods: {
        ...mapActions('meetingList', {
            fetchMeetings: meetingListActionTypes.FETCH_MEETINGS,
        }),
        ...mapActions('calendar', { onDelete: actionTypes.deleteOne }),
        ...mapActions('modalNotifications', {
            success: actionTypesModalNotifications.success,
        }),
        ...mapActions('confirmer', {
            confrimHandle: actionTypesConfirmer.confirmHandle,
        }),
        ...mapActions('clients', { getClient: actionTypesClients.getClient }),
        ...mapActions('orders', { getOrder: actionTypesOrders.loadOrder }),
        text: function (text) {
            return this.textContent[regularToSnakeCase(text)]
        },
        intervalFormat: function (interval) {
            return interval.time
        },
        eventName: function (name) {
            return name
        },
        onSwitchType: function (type) {
            this.type = type
            this.$emit('change-type', type)
        },
        onPrev: function () {
            this.$refs.calendar.prev()
            this.onChange()
        },
        onNext: function () {
            this.$refs.calendar.next()
            this.onChange()
        },
        moveToCurrent: function () {
            this.value = format(new Date(), 'yyyy-MM-dd')
            this.onChange()
        },
        onChange: function () {
            let range

            if (this.value) {
                range = format(parseISO(this.value, 1), 'yyyy-MM')
            } else if (!this.value && this.start) {
                range = format(this.start, 'yyyy-MM')
            } else {
                return
            }

            const detail = {
                type: this.typeOfMeetings,
                range,
                search: this.searchQuery,
            }
            this.$emit('load-range', detail)
        },
        changeHandle: function ({ start, end }) {
            this.range.start = start
            this.range.end = end
        },
        selectDay: function (object) {
            this.$emit('select-day', object.date)
            this.createMeeting(object)
        },
        createMeeting: function ({ date, hour, minute }) {
            let time = null
            if (hour && minute) time = parserForTime({ hour, minute })
            this.$emit('create-meeting', { date, time })
        },
        filterByMeetingManager: function (meetings) {
            const filteredMeetings = meetings.filter((meeting) => {
                if (meeting.category && meeting.category === 'holidays') {
                    return true
                }

                if (!this.isMeetingOfType(meeting)) {
                    return false
                }

                if (meeting.meetingManager) {
                    return this.selectedManagers.some(
                        (manager) =>
                            meeting.meetingManager.nickName.toLowerCase() ===
                            manager.nickName.toLowerCase()
                    )
                }

                return false
            })

            if (
                this.typeOfMeetings === 'pick_up_new' &&
                this.selectedManagers != ''
            ) {
                return filteredMeetings
            }

            return meetings.filter((meeting) => {
                if (meeting.category && meeting.category === 'holidays') {
                    return true
                }

                return this.isMeetingOfType(meeting)
            })
        },
        openEvent: function ({ nativeEvent, event }) {
            nativeEvent.stopPropagation()
            if (event.category === 'holidays') return

            const open = () => {
                this.selectedEvent = event
                this.selectedElement = nativeEvent.target
                requestAnimationFrame(() =>
                    requestAnimationFrame(() => (this.selectedOpen = true))
                )
            }

            if (this.selectedOpen) {
                this.selectedOpen = false
                requestAnimationFrame(() => requestAnimationFrame(() => open()))
            } else {
                open()
            }
        },
        closeEvent: function () {
            this.selectedOpen = false
        },
        deleteHandle: function (id, wigId) {
            if (!id) {
                id = this.selectedEvent.id
            }

            if (!id) {
                wigId = this.selectedEvent.wig_id || ''
            }

            this.confrimHandle(this.textContent['message-delete-meeting'])
                .then(() => {
                    this.onDelete({ id, wigId })
                        .then(() => {
                            this.selectedOpen = false
                            this.success('Meeting has been canceled')
                            this.fetchMeetings()
                        })
                        .catch(() => {})
                })
                .catch(() => {})
        },
        updateHandle: function (event) {
            this.$emit('update-handle', event)
        },
        goToWigs: function () {
            wigTransitions.moveToWigPageInNewTab(this.selectedEvent.wig_id)
        },
        goToClient: function () {
            this.getClient(this.selectedEvent.client_id)
        },
        weekdayFormat: function ({ weekday }) {
            const test = this.isRtl
                ? ['א׳', 'ב׳', 'ג׳', 'ד׳', 'ה׳']
                : ['sun', 'mon', 'tue', 'wed', 'thu']
            return test[weekday]
        },
        toHebrewPhoneFormat(meeting) {
            const phone = meeting?.client?.phone
            return parseFromInternToHePhoneFormat(phone)
        },
        isPickUp: function () {
            return (
                this.typeOfMeetings === 'pick_up_new' ||
                this.typeOfMeetings === 'pick_up_repairs'
            )
        },
        pickUpIntervalChange: function () {
            if (this.isPickUp()) {
                this.intervalCount = 17
                return
            }

            return 12
        },
        checkFilterPickUpNew: function (meetings, filteredMeetings) {
            let selectedMeetings = meetings
        },
        isMeetingOfType: function (meeting) {
            return (
                meeting.calendar
                    .replace(/ Wigs/, '')
                    .replace(/[-\s]/g, '_')
                    .toLowerCase() === this.typeOfMeetings
            )
        },
        searchQueryUpdate: function () {
            if (this.searchQueryTimeout) {
                clearTimeout(this.searchQueryTimeout)
            }

            this.searchQueryTimeout = setTimeout(() => {
                this.onChange()
                this.searchQueryTimeout = null
            }, 500)
        },
        getUniqueDatesByDate,
    },
}
</script>

<style lang="scss" scoped>
.calendar-main {
    &__head {
        display: flex;
        flex-direction: column-reverse;
        margin: 15px 0;
        align-items: center;
        justify-content: space-between;

        @include tablet-large-up {
            flex-direction: row;
        }
    }

    &__left-action {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        @media print {
            display: none;
        }
    }

    &__right-action {
        margin-right: auto;
        margin-left: 0;

        @include tablet-large-up {
            margin-right: 0;
            margin-left: auto;

            @include for-hebrew {
                margin-right: auto;
                margin-left: 0;
            }
        }

        @media print {
            display: none;
        }
    }

    &__title {
        order: -1;
        text-align: center;
        margin: 0 0 10px;

        @include mobile-xs-up {
            order: 0;
        }

        @include tablet-large-up {
            margin: 0 auto;
        }
    }
}

.meeting-more {
    padding: 5px;
    border-radius: 5px;
    background: $color-primary-light;
    width: 100%;
    font-size: 13px;

    &__head {
        display: flex;
        justify-content: flex-end;
    }

    &__time {
        font-weight: 700;
        font-size: 1.1em;
        margin-bottom: 5px;
    }

    &__manager {
        font-weight: 700;
        font-size: 1.1em;
        margin-bottom: 5px;
    }

    &__body {
        padding: 0 0 5px;
        margin-bottom: 5px;
        border-bottom: 1px solid #eceeef;
    }

    &__actions {
        display: flex;
        justify-content: space-evenly;
        padding-bottom: 5px;
    }

    &__old-list {
        margin-top: 5px;
        padding-top: 5px;
        border-top: 1px solid #eceeef;
    }
}

.left-action {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include tablet-large-up {
        justify-content: flex-start;
        gap: 8px;
    }

    @media print {
        display: none;
    }

    &__btn {
        width: 48%;

        @include mobile-xs-up {
            width: auto;
        }
    }

    &__today {
        width: 100%;
        order: -1;

        @include mobile-xs-up {
            order: 0;
            width: auto;
        }
    }

    &__loader {
        width: 30px;
        margin: 0 8px;
    }

    &__search-query {
        padding-bottom: 3px;
        margin-top: 16px;
        width: 100%;

        @include tablet-large-up {
            margin: 0 8px;
            width: auto;
        }
    }
}
</style>
