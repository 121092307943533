<template>
    <div class="wig-changes">
        <div class="wig-changes__wrapper">
            <div v-if="hasChanges" class="wig-changes__changes">
                <div>הודעה על שינוי/עידכון בכרטיס הפאה של</div>

                <div class="wig-changes__client">{{ changes.client }}</div>
                <div>{{ changes.title }}:</div>
                <div v-if="parsedContent">
                    <div
                        v-for="(value, key) in parsedContent"
                        :key="key"
                        class="wig-changes__content-item"
                    >
                        <div class="wig-changes__content-key">
                            {{ $t(`shared.orderProperties.${key}`) }}:
                        </div>
                        {{ getContentValueByTranslateHandle(value) }}
                    </div>
                </div>

                <div class="wig-changes__created">
                    {{ $t(`shared.orderProperties.createdAt`) }}:
                    {{ formatDateTime(changes.created_at) }}
                </div>
            </div>

            <div v-else>אין שינויים זמינים עבור מזהה זה.</div>
        </div>
    </div>
</template>

<script>
import languageMixin from '@/mixins/language.mixin'
import languageOrderExtraMixin from '@/mixins/language-order-extra.mixin'
import { mapActions, mapGetters } from 'vuex'
import { getterTypes } from '@/store/modules/wig-changes'
import { actionTypes } from '@/store/modules/wig-changes'

export default {
    mixins: [languageMixin, languageOrderExtraMixin],
    data() {
        return {}
    },
    computed: {
        ...mapGetters('wigChanges', {
            changes: getterTypes.getWigChanges,
        }),
        changeId() {
            return this.$route.params.changeID
        },
        parsedContent() {
            try {
                return this.changes && this.changes.content
                    ? JSON.parse(this.changes.content)
                    : {}
            } catch (e) {
                console.error('Error parsing content:', e)
                return {}
            }
        },
        hasChanges() {
            return !!this.changes && Object.keys(this.changes).length > 0
        },
    },

    created() {
        this.fetchWigChanges()
    },
    methods: {
        ...mapActions('wigChanges', {
            loadChanges: actionTypes.loadWigChanges,
        }),
        fetchWigChanges() {
            if (this.changeId) {
                this.loadChanges(this.changeId)
            }
        },
        toCamelCase: function (str) {
            return str
                .toLowerCase()
                .replace(/[_\s]+(.)?/g, (_, chr) =>
                    chr ? chr.toUpperCase() : ''
                )
        },
        containsHebrew: function (str) {
            const regex = /[\u0590-\u05FF]/
            return regex.test(str)
        },
        getContentValueByTranslateHandle: function (str) {
            if (this.containsHebrew(str)) {
                return str
            }

            const translatedKey = `shared.orderProperties.${this.toCamelCase(
                str
            )}`
            const translatedText = this.$t(translatedKey)

            if (translatedText.includes('Translation not found for key')) {
                return str
            }

            return translatedText
        },
        formatDateTime: function (dateString) {
            const date = new Date(dateString)
            const formattedDate = `${('0' + date.getDate()).slice(-2)}-${(
                '0' +
                (date.getMonth() + 1)
            ).slice(-2)}-${date.getFullYear()}`

            const formattedTime = `${('0' + date.getHours()).slice(-2)}:${(
                '0' + date.getMinutes()
            ).slice(-2)}`

            return `${formattedDate} ${formattedTime}`
        },
    },
}
</script>

<style lang="scss">
.wig-changes {
    padding: 84px 8px 0;

    @include tablet-up {
        padding: 84px 16px 0;
    }
    @include desktop-up {
        padding: 84px 20px 0;
    }

    &__wrapper {
        background: #fff;
        padding: 20px;
        border-radius: 12px;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
        font-size: 14px;
        @include tablet-up {
            font-size: 20px;
        }
        @include desktop-up {
            font-size: 16px;
        }
    }
    &__client {
        font-weight: 700;
        margin-bottom: 12px;
        @include tablet-up {
            margin-bottom: 20px;
        }
    }
    &__content {
        &-item {
            display: flex;
            flex-direction: row;
            gap: 4px;
            padding-top: 6px;
            padding-bottom: 6px;
            border-bottom: 1px solid rgba(30, 30, 30, 0.2);
            @include tablet-up {
                padding-top: 8px;
                padding-bottom: 8px;
            }
            @include desktop-up {
                padding-top: 6px;
                padding-bottom: 6px;
            }
            &:last-child {
                border-bottom: none;
            }
        }

        &-key {
            font-weight: 700;
            width: 100%;
            max-width: 120px;
            @include tablet-up {
                max-width: 170px;
            }
            @include desktop-up {
                max-width: 140px;
            }
        }
    }
    &__created {
        margin-top: 8px;
        opacity: 50%;
        font-size: 12px;
        @include tablet-up {
            font-size: 16px;
            margin-top: 16px;
        }
        @include desktop-up {
            font-size: 12px;
            margin-top: 8px;
        }
    }
}
</style>
