<template>
    <ul class="meetings">
        <li
            v-for="{
                id,
                startAt,
                calendar,
                comment,
                createdAt,
                createdBy,
                meetingManager,
            } of meetingsList"
            :key="id"
            class="meetings__item"
            @click="
                () => {
                    goToSelectedMeeting({ startAt, calendar })
                }
            "
        >
            <div class="meetings__body">
                <div class="meetings__headline">
                    <div class="meetings__icon">
                        <v-icon color="#967adc">mdi-calendar-clock</v-icon>
                    </div>
                    <p>
                        <DateCell :value="startAt" :utc="false" />
                    </p>
                </div>
                <div v-if="meetingManager?.id" class="meetings__headline">
                    <div class="meetings__icon">
                        <v-icon color="#967adc">mdi-calendar-clock</v-icon>
                    </div>
                    <p>
                        {{ meetingManager.nickName }}
                    </p>
                </div>
                <p class="meetings__comment">
                    {{ comment }}
                </p>
            </div>
            <div class="meetings__foot">
                <p>
                    {{ $t('shared.clientMeetingList.createdAt') }}:
                    <span class="date-container">
                        <DateCell :value="createdAt" />
                    </span>
                </p>
                <p>
                    {{ $t('shared.clientMeetingList.createdBy') }}:
                    <span>{{ createdBy }}</span>
                </p>
            </div>
        </li>
    </ul>
</template>

<script>
import { mapActions } from 'vuex'
import { actionTypes } from '@/store/modules/modals'
import { actionTypes as actionTypesModalNotifications } from '@/store/modules/modal-notifications'
import DateCell from '@/components/common/cells/date-cell.vue'

export default {
    name: 'ClientMeetingsList',
    components: { DateCell },
    props: {
        meetings: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        meetingsList: function () {
            return this.meetings.map((meeting) => {
                return {
                    id: meeting.id,
                    startAt: meeting.start_at,
                    calendar: meeting.calendar,
                    comment: meeting.subject,
                    createdAt: meeting.created_at,
                    createdBy: meeting.created_by,
                    meetingManager: meeting.meeting_manager || null,
                }
            })
        },
    },
    methods: {
        ...mapActions('modalNotifications', {
            warningMessage: actionTypesModalNotifications.warning,
        }),
        ...mapActions('modals', { onOpen: actionTypes.openPopUp }),
        goToSelectedMeeting: function ({ startAt, calendar }) {
            if (!this.getAllowed(['admin', 'manager', 'usa_manager'])) {
                this.warningMessage(
                    'You do not have permission to view this page'
                )
                return null
            }

            let type = ''
            switch (calendar) {
                case 'Orders':
                    type = 'orders'
                    break
                case 'Repairs':
                    type = 'repairs'
                    break
                case 'Pick-up New Wigs':
                    type = 'pick_up_new'
                    break
                case 'Pick-up Repairs':
                    type = 'pick_up_repairs'
                    break
                case 'us_agent':
                    type = 'us_agent'
                    break
                default:
                    console.log('error')
            }

            this.onOpen({
                name: 'Calendar',
                payload: {
                    action: 'to-date',
                    type,
                    selectDate: startAt,
                },
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.meetings {
    padding: 16px;

    &__item {
        align-items: center;
        font-size: 14px;
        cursor: pointer;
        border: 2px solid #967adc;
        border-radius: 16px;
        padding: 8px 16px;

        & + & {
            margin-top: 16px;
        }
    }

    &__body {
    }

    &__headline {
        font-weight: 700;
        font-size: 1.1em;
        display: flex;
        margin-bottom: 4px;

        p {
            direction: ltr;
            margin-bottom: 0;
        }
    }

    &__icon {
        margin-right: 8px;

        @include for-hebrew {
            margin-right: 0;
            margin-left: 8px;
        }
    }

    &__foot {
        display: flex;
        justify-content: space-between;
        border-top: 1px dotted #c5c5c5;
        font-size: 0.8em;
        padding-top: 5px;

        span {
            font-weight: 700;
        }

        p {
            margin-bottom: 0;
        }
    }

    &__desc {
    }

    &__comment {
        font-weight: 700;
        font-size: 0.9em;
        padding-bottom: 0;
        margin-bottom: 4px;
    }
}
</style>
