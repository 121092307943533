<template>
    <v-app :class="{ rtl: isRtl }">
        <v-main>
            <component :is="layout"></component>
        </v-main>
        <portal-target name="slide-out-container"> </portal-target>
    </v-app>
</template>

<script>
import MainLayout from '@/layouts/main-layout'
import EmptyLayout from '@/layouts/empty-layout'
import PrintLayout from '@/layouts/print-layout'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
    actionTypes as actionTypesAuth,
    gettersTypes as getterTypesAuth,
} from '@/store/modules/auth'
import { actionTypes } from '@/store/modules/language-pack'
import {
    getterTypes as getterTypesConfiguration,
    mutationTypes,
} from '@/store/modules/configuration'

export default {
    name: 'App',
    components: {
        MainLayout,
        EmptyLayout,
        PrintLayout,
    },
    computed: {
        layout: function () {
            return `${this.$route.meta.layout || 'empty'}-layout`
        },
        ...mapGetters('configuration', {
            getLanguage: getterTypesConfiguration.getLanguage,
        }),
        ...mapGetters('auth', {
            getUserName: getterTypesAuth.getName,
        }),
        isRtl: function () {
            return this.getLanguage === 'Hebrew'
        },
    },
    mounted() {
        this.loadLanguage('language')
        this.loadLanguage('languageExtra')
        if (this.isRtl) {
            this.$vuetify.rtl = this.isRtl
            this.changeLanguage('Hebrew')
        }
    },
    updated() {
        this.$vuetify.rtl = this.isRtl
        this.getCurrentUser()
    },
    methods: {
        ...mapActions('auth', {
            getCurrentUser: actionTypesAuth.getCurrentUser,
        }),
        ...mapActions('languagePack', {
            loadLanguage: actionTypes.getLanguagePack,
        }),
        ...mapMutations('configuration', {
            changeLanguage: mutationTypes.changeLanguage,
        }),
    },
}
</script>

<style lang="scss">
@import '~@/styles/main.scss';

#app {
    width: 100%;
}

.v-application {
    font-family: $font-varela-round !important;
}

.app {
    &__wrapper {
        display: flex;
        width: 100%;
        position: relative;
    }

    &__side-bar-wrapper {
        position: relative;
        width: 60px;
        transition: all ease 300ms;

        &.is-active {
            width: 240px;
        }
    }

    &__header {
        position: sticky;
        top: 0;
        min-height: 75px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.35);
        background: #fff;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0 15px;
    }

    &__body {
        position: relative;
        flex-grow: 1;
    }

    &__footer {
    }
}
</style>
