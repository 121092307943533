import api from '@/api/clients'
import { actionTypes as actionTypesModals } from '@/store/modules/modals'
import { actionTypes as actionTypesOrders } from '@/store/modules/orders'
import { actionTypes as actionTypesCalendar } from '@/store/modules/calendar'

import popUpTypes from '@/types/pop-up-types'
import { getFullDateForClient } from '@/helpers/getDateInFormat'
import { ordersV2ActionTypes } from '@/store/modules/orders_v2/types'
import { clientsV2ActionTypes } from '@/store/modules/clients_v2/types'

const { clientContext } = popUpTypes

const state = () => ({
    clients: [],
    selectedClient: {},
})

export const getterTypes = {
    getAllClients: '[clients] Get all clients',
    total: '[clients] Get count of all clients',
    getClient: '[clients] Get the one client',
}

export const mutationTypes = {
    addClients: '[clients] Add new clients',
    selectClient: '[clients] Select or change the one client',
    clearSelected: '[clients] Clear all data of selected client',
    addNewClient: '[clients] Add the new one',
    updateClient: '[clients] Update client',
    deleteClient: '[clients] Delete client',
    restoreClient: '[clients] Restore client',
}

export const actionTypes = {
    getAllClients: '[clients] Load all clients',
    getAllDeleted: '[clients] Load all deleted clients',
    getClient: '[clients] Load the one client',
    addClient: '[clients] Add the new one',
    updateClient: '[clients] Update selected client',
    deleteClient: '[clients] Delete selected client',
    restoreClient: '[clients] Restore selected client',
    checkClientIsAlready:
        '[clients] Check client with the selected phone action',
}

const getters = {
    [getterTypes.getAllClients]: ({ clients }) => clients,
    [getterTypes.total]: ({ clients }) => clients.length,
    [getterTypes.getClient]: ({ selectedClient }) => selectedClient,
}

const mutations = {
    [mutationTypes.addClients](state, { clients }) {
        state.clients = clients.map((c) => ({
            ...c,
            type: c.isDeleted === 1 ? 'deleted' : 'existing',
        }))
    },
    [mutationTypes.selectClient](state, client) {
        state.selectedClient = client
    },
    [mutationTypes.clearSelected](state) {
        state.selectedClient = {}
    },
    [mutationTypes.addNewClient](state, client) {
        state.clients = [...state.clients, { ...client, type: 'existing' }]
    },
    [mutationTypes.updateClient](state, updatedclient) {
        state.clients = state.clients.map((client) =>
            client.id === updatedclient.id ? updatedclient : client
        )
    },
    [mutationTypes.deleteClient](state, id) {
        state.clients = state.clients.map((client) =>
            client.id === id ? { ...client, type: 'deleted' } : client
        )
    },
    [mutationTypes.restoreClient](state, id) {
        state.clients = state.clients.map((client) =>
            client.id === id ? { ...client, type: 'existing' } : client
        )
    },
}

const actions = {
    async [actionTypes.getAllClients]({ commit, state, rootState }) {
        let clients = []
        const role = rootState?.auth?.user?.role?.name
        if (state.clients.length !== 0 || !role) return

        try {
            switch (role) {
                case 'USA_MANAGER':
                    clients = [
                        ...clients,
                        ...((await api.clients.getAllByUsaManager())?.data ||
                            []),
                    ]
                    break
                default:
                    clients = [
                        ...clients,
                        ...((await api.clients.getAll())?.data || []),
                    ]
                    clients = [
                        ...clients,
                        ...((await api.clients.getAllDeleted())?.data || []),
                    ]
            }
            commit(mutationTypes.addClients, {
                clients,
            })
            return clients
        } catch (e) {
            console.log(e)
            return e
        }
    },
    [actionTypes.getAllDeleted]({ commit }) {
        // const role = rootState?.auth?.user?.role?.name
        // if (state.clients.length !== 0 || !role) return
        return new Promise((res, rej) => {
            api.clients
                .getAllDeleted()
                .then(({ data }) => {
                    res(data)
                    commit(mutationTypes.addClients, {
                        clients: data,
                        type: 'deleted',
                    })
                    return data
                })
                .catch((error) => {
                    rej(error)
                })
        })
    },
    [actionTypes.getClient]({ commit, dispatch }, id) {
        return new Promise((res, rej) => {
            api.clients
                .getClient(id)
                .then((data) => {
                    res(data.data)
                    commit(mutationTypes.selectClient, data.data)
                    dispatch(
                        `modals/${actionTypesModals.openPopUp}`,
                        clientContext,
                        {
                            root: true,
                        }
                    )
                })
                .catch((error) => {
                    rej(error)
                    console.log(error)
                })
        })
    },
    [actionTypes.addClient]({ commit, dispatch }, { client, options }) {
        return new Promise((res, rej) => {
            api.clients
                .addClient(client)
                .then((data) => {
                    const newClient = {
                        ...client,
                        address: `${client.country} ${client.city}`,
                        full_name: `${client.name} ${client.surname}`,
                        id: data.id,
                        meetings: [],
                        created_at: getFullDateForClient(Date.now()),
                        updated_at: getFullDateForClient(Date.now()),
                        isDeleted: 0,
                    }
                    if (!options?.withoutOpenClientCard)
                        commit(mutationTypes.selectClient, newClient)
                    commit(mutationTypes.addNewClient, newClient)
                    dispatch(
                        `clientsV2/${clientsV2ActionTypes.FETCH_ITEMS}`,
                        {},
                        { root: true }
                    )
                    res(data)
                })
                .catch((error) => {
                    rej(error.response.data.errors)
                })
        })
    },
    [actionTypes.updateClient]({ commit, dispatch }, { client, id }) {
        return new Promise((res, rej) => {
            api.clients
                .updateClient(client, id)
                .then((data) => {
                    const newClient = {
                        ...client,
                        address: `${client.country} ${client.city}`,
                        full_name: `${client.name} ${client.surname}`,
                        id: data || id,
                        meetings: [],
                        updated_at: getFullDateForClient(Date.now()),
                    }
                    commit(mutationTypes.selectClient, newClient)
                    commit(mutationTypes.updateClient, newClient)
                    dispatch(
                        `orders/${actionTypesOrders.updateClient}`,
                        newClient,
                        {
                            root: true,
                        }
                    )
                    dispatch(
                        `calendar/${actionTypesCalendar.updateClient}`,
                        newClient,
                        {
                            root: true,
                        }
                    )
                    dispatch(
                        `clientsV2/${clientsV2ActionTypes.FETCH_ITEMS}`,
                        {},
                        { root: true }
                    )
                    dispatch(
                        `ordersV2/${ordersV2ActionTypes.FETCH_ORDERS}`,
                        {},
                        { root: true }
                    )
                    res(data)
                })
                .catch((error) => {
                    rej(error.response.data.errors)
                })
        })
    },
    [actionTypes.deleteClient]({ commit, dispatch }, id) {
        return new Promise((res, rej) => {
            api.clients
                .deleteClient(id)
                .then((data) => {
                    res(data)
                    commit(mutationTypes.deleteClient, id)
                    dispatch(
                        `clientsV2/${clientsV2ActionTypes.FETCH_ITEMS}`,
                        {},
                        { root: true }
                    )
                })
                .catch((errors) => {
                    rej(errors)
                })
        })
    },
    // eslint-disable-next-line no-unused-vars
    [actionTypes.restoreClient]({ commit, dispatch }, id) {
        return new Promise((res, rej) => {
            api.clients
                .restoreClient(id)
                .then((data) => {
                    res(data)
                    commit(mutationTypes.restoreClient, id)
                    dispatch(
                        `clientsV2/${clientsV2ActionTypes.FETCH_ITEMS}`,
                        {},
                        { root: true }
                    )
                })
                .catch((errors) => {
                    rej(errors)
                })
        })
    },
    // eslint-disable-next-line no-unused-vars
    [actionTypes.checkClientIsAlready]({ commit }, phone) {
        return new Promise((res, rej) => {
            api.clients
                .checkPhone(phone)
                .then((user) => {
                    res(user)
                })
                .catch((er) => {
                    rej(er)
                })
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
